.shake, .shake-little, .shake-slow, .shake-hard, .shake-horizontal, .shake-vertical, .shake-rotate, .shake-opacity, .shake-crazy, .shake-chunk {
  display: inherit;
  transform-origin: center;
}

.shake-freeze, .shake-constant.shake-constant--hover:hover, .shake-trigger:hover .shake-constant.shake-constant--hover {
  animation-play-state: paused;
}

.shake-freeze:hover, .shake-trigger:hover .shake-freeze, .shake:hover, .shake-trigger:hover .shake, .shake-little:hover, .shake-trigger:hover .shake-little, .shake-slow:hover, .shake-trigger:hover .shake-slow, .shake-hard:hover, .shake-trigger:hover .shake-hard, .shake-horizontal:hover, .shake-trigger:hover .shake-horizontal, .shake-vertical:hover, .shake-trigger:hover .shake-vertical, .shake-rotate:hover, .shake-trigger:hover .shake-rotate, .shake-opacity:hover, .shake-trigger:hover .shake-opacity, .shake-crazy:hover, .shake-trigger:hover .shake-crazy, .shake-chunk:hover, .shake-trigger:hover .shake-chunk {
  animation-play-state: running;
}

@keyframes shake {
  2% {
    transform: translate(1.5px, 1.5px)rotate(1.5deg);
  }

  4% {
    transform: translate(.5px, -.5px)rotate(.5deg);
  }

  6% {
    transform: translate(1.5px, 2.5px)rotate(1.5deg);
  }

  8% {
    transform: translate(.5px, 2.5px)rotate(.5deg);
  }

  10% {
    transform: translate(2.5px, 1.5px)rotate(.5deg);
  }

  12% {
    transform: translate(-.5px, -1.5px)rotate(-.5deg);
  }

  14% {
    transform: translate(1.5px, 2.5px)rotate(-.5deg);
  }

  16% {
    transform: translate(2.5px, -1.5px)rotate(1.5deg);
  }

  18% {
    transform: translate(2.5px, 2.5px)rotate(-.5deg);
  }

  20% {
    transform: translate(-.5px, -1.5px)rotate(1.5deg);
  }

  22% {
    transform: translate(-1.5px, -.5px)rotate(.5deg);
  }

  24% {
    transform: translate(2.5px, -1.5px)rotate(1.5deg);
  }

  26% {
    transform: translate(.5px, 2.5px)rotate(1.5deg);
  }

  28% {
    transform: translate(-.5px, 2.5px)rotate(-.5deg);
  }

  30% {
    transform: translate(.5px, 2.5px)rotate(1.5deg);
  }

  32% {
    transform: translate(-1.5px, -.5px)rotate(-.5deg);
  }

  34% {
    transform: translate(.5px, -.5px)rotate(.5deg);
  }

  36% {
    transform: translate(-1.5px, .5px)rotate(1.5deg);
  }

  38% {
    transform: translate(-1.5px, 1.5px)rotate(1.5deg);
  }

  40% {
    transform: translate(2.5px, .5px)rotate(.5deg);
  }

  42% {
    transform: translate(.5px, .5px)rotate(1.5deg);
  }

  44% {
    transform: translate(-.5px, .5px)rotate(.5deg);
  }

  46% {
    transform: translate(2.5px, 1.5px)rotate(1.5deg);
  }

  48% {
    transform: translate(2.5px, 1.5px)rotate(-.5deg);
  }

  50% {
    transform: translate(-1.5px, 2.5px)rotate(-.5deg);
  }

  52% {
    transform: translate(.5px, -1.5px)rotate(1.5deg);
  }

  54% {
    transform: translate(.5px, -1.5px)rotate(.5deg);
  }

  56% {
    transform: translate(1.5px, -1.5px)rotate(-.5deg);
  }

  58% {
    transform: translate(1.5px, .5px)rotate(.5deg);
  }

  60% {
    transform: translate(-.5px, .5px)rotate(.5deg);
  }

  62% {
    transform: translate(.5px, .5px)rotate(-.5deg);
  }

  64% {
    transform: translate(-1.5px, -.5px)rotate(1.5deg);
  }

  66% {
    transform: translate(-1.5px, 2.5px)rotate(-.5deg);
  }

  68% {
    transform: translate(-1.5px, 1.5px)rotate(.5deg);
  }

  70% {
    transform: translate(.5px, 1.5px)rotate(.5deg);
  }

  72% {
    transform: translate(-1.5px, 2.5px)rotate(.5deg);
  }

  74% {
    transform: translate(2.5px, .5px)rotate(-.5deg);
  }

  76% {
    transform: translate(.5px, -1.5px)rotate(1.5deg);
  }

  78% {
    transform: translate(.5px, 1.5px)rotate(.5deg);
  }

  80% {
    transform: translate(-.5px, -.5px)rotate(.5deg);
  }

  82% {
    transform: translate(1.5px, 1.5px)rotate(1.5deg);
  }

  84% {
    transform: translate(.5px, .5px)rotate(.5deg);
  }

  86% {
    transform: translate(-.5px, .5px)rotate(1.5deg);
  }

  88% {
    transform: translate(1.5px, .5px)rotate(1.5deg);
  }

  90% {
    transform: translate(-.5px, 2.5px)rotate(-.5deg);
  }

  92% {
    transform: translate(-1.5px, -1.5px)rotate(1.5deg);
  }

  94% {
    transform: translate(-1.5px, 2.5px)rotate(1.5deg);
  }

  96% {
    transform: translate(-1.5px, -1.5px)rotate(1.5deg);
  }

  98% {
    transform: translate(1.5px, -1.5px)rotate(1.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake:hover, .shake-trigger:hover .shake, .shake.shake-freeze, .shake.shake-constant {
  animation-name: shake;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-little {
  2% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  4% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  6% {
    transform: translate(0)rotate(.5deg);
  }

  8% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  10% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  12% {
    transform: translate(1px)rotate(.5deg);
  }

  14% {
    transform: translate(0)rotate(.5deg);
  }

  16% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  18% {
    transform: translate(1px)rotate(.5deg);
  }

  20% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  22% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  24% {
    transform: translate(1px)rotate(.5deg);
  }

  26% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  28% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  30% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  32% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  34% {
    transform: translate(1px)rotate(.5deg);
  }

  36% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  38% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  40% {
    transform: translate(0)rotate(.5deg);
  }

  42% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  44% {
    transform: translate(1px)rotate(.5deg);
  }

  46% {
    transform: translate(0)rotate(.5deg);
  }

  48% {
    transform: translate(1px)rotate(.5deg);
  }

  50% {
    transform: translate(1px)rotate(.5deg);
  }

  52% {
    transform: translate(0)rotate(.5deg);
  }

  54% {
    transform: translate(1px)rotate(.5deg);
  }

  56% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  58% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  60% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  62% {
    transform: translate(0)rotate(.5deg);
  }

  64% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  66% {
    transform: translate(0)rotate(.5deg);
  }

  68% {
    transform: translate(1px)rotate(.5deg);
  }

  70% {
    transform: translate(1px)rotate(.5deg);
  }

  72% {
    transform: translate(1px)rotate(.5deg);
  }

  74% {
    transform: translate(0)rotate(.5deg);
  }

  76% {
    transform: translate(0)rotate(.5deg);
  }

  78% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  80% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  82% {
    transform: translate(1px)rotate(.5deg);
  }

  84% {
    transform: translate(1px)rotate(.5deg);
  }

  86% {
    transform: translate(1px)rotate(.5deg);
  }

  88% {
    transform: translate(0)rotate(.5deg);
  }

  90% {
    transform: translate(0, 1px)rotate(.5deg);
  }

  92% {
    transform: translate(1px, 1px)rotate(.5deg);
  }

  94% {
    transform: translate(1px)rotate(.5deg);
  }

  96% {
    transform: translate(1px)rotate(.5deg);
  }

  98% {
    transform: translate(1px)rotate(.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-little:hover, .shake-trigger:hover .shake-little, .shake-little.shake-freeze, .shake-little.shake-constant {
  animation-name: shake-little;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-slow {
  2% {
    transform: translate(6px, -6px)rotate(2.5deg);
  }

  4% {
    transform: translate(-5px, 1px)rotate(1.5deg);
  }

  6% {
    transform: translate(7px, -3px)rotate(-2.5deg);
  }

  8% {
    transform: translate(-7px, -5px)rotate(2.5deg);
  }

  10% {
    transform: translate(-3px, 2px)rotate(-1.5deg);
  }

  12% {
    transform: translate(3px, 6px)rotate(3.5deg);
  }

  14% {
    transform: translate(-7px, 2px)rotate(-1.5deg);
  }

  16% {
    transform: translate(-6px, 6px)rotate(-2.5deg);
  }

  18% {
    transform: translate(6px)rotate(1.5deg);
  }

  20% {
    transform: translate(9px, 9px)rotate(2.5deg);
  }

  22% {
    transform: translate(7px, -9px)rotate(2.5deg);
  }

  24% {
    transform: translate(2px, 1px)rotate(.5deg);
  }

  26% {
    transform: translate(10px, 4px)rotate(-.5deg);
  }

  28% {
    transform: translate(-9px, 3px)rotate(3.5deg);
  }

  30% {
    transform: translate(10px, 5px)rotate(1.5deg);
  }

  32% {
    transform: translate(6px, 8px)rotate(-2.5deg);
  }

  34% {
    transform: translate(9px, 9px)rotate(3.5deg);
  }

  36% {
    transform: translate(-5px)rotate(-.5deg);
  }

  38% {
    transform: translate(-2px, -2px)rotate(.5deg);
  }

  40% {
    transform: translate(-3px, 8px)rotate(2.5deg);
  }

  42% {
    transform: translate(-7px, 8px)rotate(3.5deg);
  }

  44% {
    transform: translate(6px, -6px)rotate(.5deg);
  }

  46% {
    transform: translate(1px, -2px)rotate(3.5deg);
  }

  48% {
    transform: translate(-5px, 7px)rotate(-2.5deg);
  }

  50% {
    transform: translate(-8px, 1px)rotate(2.5deg);
  }

  52% {
    transform: translate(-9px, -2px)rotate(1.5deg);
  }

  54% {
    transform: translate(4px, -9px)rotate(3.5deg);
  }

  56% {
    transform: translate(-6px, -1px)rotate(.5deg);
  }

  58% {
    transform: translate(-3px, -6px)rotate(-.5deg);
  }

  60% {
    transform: translate(8px, 4px)rotate(-.5deg);
  }

  62% {
    transform: translate(0, -9px)rotate(.5deg);
  }

  64% {
    transform: translate(9px, -2px)rotate(3.5deg);
  }

  66% {
    transform: translate(-7px, 10px)rotate(3.5deg);
  }

  68% {
    transform: translate(-7px, -9px)rotate(-1.5deg);
  }

  70% {
    transform: translate(9px, -2px)rotate(-2.5deg);
  }

  72% {
    transform: translate(-4px)rotate(2.5deg);
  }

  74% {
    transform: translate(1px, -2px)rotate(-1.5deg);
  }

  76% {
    transform: translate(-8px, -5px)rotate(2.5deg);
  }

  78% {
    transform: translate(-9px, 2px)rotate(1.5deg);
  }

  80% {
    transform: translate(-4px, 1px)rotate(-1.5deg);
  }

  82% {
    transform: translate(-6px, 10px)rotate(2.5deg);
  }

  84% {
    transform: translate(6px, -3px)rotate(-.5deg);
  }

  86% {
    transform: translate(-1px, -2px)rotate(1.5deg);
  }

  88% {
    transform: translate(1px, 9px)rotate(1.5deg);
  }

  90% {
    transform: translate(3px, 9px)rotate(-.5deg);
  }

  92% {
    transform: translate(-3px, -1px)rotate(.5deg);
  }

  94% {
    transform: translate(-7px, 4px)rotate(1.5deg);
  }

  96% {
    transform: translate(6px, 1px)rotate(.5deg);
  }

  98% {
    transform: translate(-8px, 2px)rotate(-.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-slow:hover, .shake-trigger:hover .shake-slow, .shake-slow.shake-freeze, .shake-slow.shake-constant {
  animation-name: shake-slow;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-hard {
  2% {
    transform: translate(-3px, 3px)rotate(-2.5deg);
  }

  4% {
    transform: translate(-3px, -2px)rotate(-1.5deg);
  }

  6% {
    transform: translate(-9px, 4px)rotate(-1.5deg);
  }

  8% {
    transform: translate(9px, 8px)rotate(2.5deg);
  }

  10% {
    transform: translate(2px, 7px)rotate(.5deg);
  }

  12% {
    transform: translate(3px, -5px)rotate(.5deg);
  }

  14% {
    transform: translate(5px, 8px)rotate(1.5deg);
  }

  16% {
    transform: translate(8px, -2px)rotate(-2.5deg);
  }

  18% {
    transform: translate(-2px, 1px)rotate(-.5deg);
  }

  20% {
    transform: translate(-4px, 10px)rotate(-.5deg);
  }

  22% {
    transform: translate(7px, 2px)rotate(2.5deg);
  }

  24% {
    transform: translate(8px)rotate(3.5deg);
  }

  26% {
    transform: translate(-7px, -8px)rotate(3.5deg);
  }

  28% {
    transform: translate(-5px, 10px)rotate(1.5deg);
  }

  30% {
    transform: translate(1px, 2px)rotate(-.5deg);
  }

  32% {
    transform: translate(4px, 4px)rotate(-1.5deg);
  }

  34% {
    transform: translate(8px, -3px)rotate(-1.5deg);
  }

  36% {
    transform: translate(8px, 9px)rotate(-2.5deg);
  }

  38% {
    transform: translate(-4px, 10px)rotate(2.5deg);
  }

  40% {
    transform: translate(5px, 2px)rotate(.5deg);
  }

  42% {
    transform: translate(2px, 7px)rotate(2.5deg);
  }

  44% {
    transform: translate(7px, 5px)rotate(.5deg);
  }

  46% {
    transform: translate(-5px)rotate(3.5deg);
  }

  48% {
    transform: translate(-2px, -7px)rotate(-.5deg);
  }

  50% {
    transform: translate(-1px, -5px)rotate(.5deg);
  }

  52% {
    transform: translate(-4px, 8px)rotate(-.5deg);
  }

  54% {
    transform: translate(8px, -3px)rotate(3.5deg);
  }

  56% {
    transform: translate(-1px, -8px)rotate(2.5deg);
  }

  58% {
    transform: translate(6px, 6px)rotate(3.5deg);
  }

  60% {
    transform: translate(0, 4px)rotate(-.5deg);
  }

  62% {
    transform: translate(2px, -7px)rotate(.5deg);
  }

  64% {
    transform: translate(0, 6px)rotate(2.5deg);
  }

  66% {
    transform: translate(5px)rotate(-.5deg);
  }

  68% {
    transform: translate(0, 3px)rotate(3.5deg);
  }

  70% {
    transform: translate(7px, -5px)rotate(-1.5deg);
  }

  72% {
    transform: translate(6px, -7px)rotate(3.5deg);
  }

  74% {
    transform: translate(1px, -5px)rotate(-2.5deg);
  }

  76% {
    transform: translate(-7px, 2px)rotate(-.5deg);
  }

  78% {
    transform: translate(-8px, 1px)rotate(1.5deg);
  }

  80% {
    transform: translate(1px, 5px)rotate(1.5deg);
  }

  82% {
    transform: translate(-4px, -5px)rotate(-1.5deg);
  }

  84% {
    transform: translate(3px, -2px)rotate(3.5deg);
  }

  86% {
    transform: translate(-6px, -5px)rotate(1.5deg);
  }

  88% {
    transform: translate(-7px, -1px)rotate(-2.5deg);
  }

  90% {
    transform: translate(10px, 2px)rotate(-2.5deg);
  }

  92% {
    transform: translate(10px, -3px)rotate(1.5deg);
  }

  94% {
    transform: translate(4px, -6px)rotate(3.5deg);
  }

  96% {
    transform: translate(9px, 7px)rotate(-1.5deg);
  }

  98% {
    transform: translate(2px, 1px)rotate(-.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-hard:hover, .shake-trigger:hover .shake-hard, .shake-hard.shake-freeze, .shake-hard.shake-constant {
  animation-name: shake-hard;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-horizontal {
  2% {
    transform: translate(3px)rotate(0);
  }

  4% {
    transform: translate(1px)rotate(0);
  }

  6% {
    transform: translate(4px)rotate(0);
  }

  8% {
    transform: translate(-2px)rotate(0);
  }

  10% {
    transform: translate(9px)rotate(0);
  }

  12% {
    transform: translate(0)rotate(0);
  }

  14% {
    transform: translate(-8px)rotate(0);
  }

  16% {
    transform: translate(-7px)rotate(0);
  }

  18% {
    transform: translate(1px)rotate(0);
  }

  20% {
    transform: translate(-5px)rotate(0);
  }

  22% {
    transform: translate(-2px)rotate(0);
  }

  24% {
    transform: translate(-5px)rotate(0);
  }

  26% {
    transform: translate(-1px)rotate(0);
  }

  28% {
    transform: translate(3px)rotate(0);
  }

  30% {
    transform: translate(-7px)rotate(0);
  }

  32% {
    transform: translate(-8px)rotate(0);
  }

  34% {
    transform: translate(4px)rotate(0);
  }

  36% {
    transform: translate(8px)rotate(0);
  }

  38% {
    transform: translate(-5px)rotate(0);
  }

  40% {
    transform: translate(-6px)rotate(0);
  }

  42% {
    transform: translate(7px)rotate(0);
  }

  44% {
    transform: translate(1px)rotate(0);
  }

  46% {
    transform: translate(3px)rotate(0);
  }

  48% {
    transform: translate(10px)rotate(0);
  }

  50% {
    transform: translate(1px)rotate(0);
  }

  52% {
    transform: translate(3px)rotate(0);
  }

  54% {
    transform: translate(-9px)rotate(0);
  }

  56% {
    transform: translate(-8px)rotate(0);
  }

  58% {
    transform: translate(7px)rotate(0);
  }

  60% {
    transform: translate(2px)rotate(0);
  }

  62% {
    transform: translate(-6px)rotate(0);
  }

  64% {
    transform: translate(-9px)rotate(0);
  }

  66% {
    transform: translate(-2px)rotate(0);
  }

  68% {
    transform: translate(4px)rotate(0);
  }

  70% {
    transform: translate(8px)rotate(0);
  }

  72% {
    transform: translate(2px)rotate(0);
  }

  74% {
    transform: translate(6px)rotate(0);
  }

  76% {
    transform: translate(-4px)rotate(0);
  }

  78% {
    transform: translate(4px)rotate(0);
  }

  80% {
    transform: translate(9px)rotate(0);
  }

  82% {
    transform: translate(6px)rotate(0);
  }

  84% {
    transform: translate(-6px)rotate(0);
  }

  86% {
    transform: translate(5px)rotate(0);
  }

  88% {
    transform: translate(5px)rotate(0);
  }

  90% {
    transform: translate(-4px)rotate(0);
  }

  92% {
    transform: translate(0)rotate(0);
  }

  94% {
    transform: translate(-6px)rotate(0);
  }

  96% {
    transform: translate(6px)rotate(0);
  }

  98% {
    transform: translate(10px)rotate(0);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-horizontal:hover, .shake-trigger:hover .shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
  animation-name: shake-horizontal;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-vertical {
  2% {
    transform: translate(0, -9px)rotate(0);
  }

  4% {
    transform: translate(0, 10px)rotate(0);
  }

  6% {
    transform: translate(0, -7px)rotate(0);
  }

  8% {
    transform: translate(0, -3px)rotate(0);
  }

  10% {
    transform: translate(0, -9px)rotate(0);
  }

  12% {
    transform: translate(0, -1px)rotate(0);
  }

  14% {
    transform: translate(0, -3px)rotate(0);
  }

  16% {
    transform: translate(0, 9px)rotate(0);
  }

  18% {
    transform: translate(0, 6px)rotate(0);
  }

  20% {
    transform: translate(0, 7px)rotate(0);
  }

  22% {
    transform: translate(0)rotate(0);
  }

  24% {
    transform: translate(0, 8px)rotate(0);
  }

  26% {
    transform: translate(0)rotate(0);
  }

  28% {
    transform: translate(0, -5px)rotate(0);
  }

  30% {
    transform: translate(0, -9px)rotate(0);
  }

  32% {
    transform: translate(0, 2px)rotate(0);
  }

  34% {
    transform: translate(0, -4px)rotate(0);
  }

  36% {
    transform: translate(0, 9px)rotate(0);
  }

  38% {
    transform: translate(0, 2px)rotate(0);
  }

  40% {
    transform: translate(0, -3px)rotate(0);
  }

  42% {
    transform: translate(0, 7px)rotate(0);
  }

  44% {
    transform: translate(0, 9px)rotate(0);
  }

  46% {
    transform: translate(0, -2px)rotate(0);
  }

  48% {
    transform: translate(0, 1px)rotate(0);
  }

  50% {
    transform: translate(0, 9px)rotate(0);
  }

  52% {
    transform: translate(0, 3px)rotate(0);
  }

  54% {
    transform: translate(0, -4px)rotate(0);
  }

  56% {
    transform: translate(0, -1px)rotate(0);
  }

  58% {
    transform: translate(0, -2px)rotate(0);
  }

  60% {
    transform: translate(0, 10px)rotate(0);
  }

  62% {
    transform: translate(0, -4px)rotate(0);
  }

  64% {
    transform: translate(0, -8px)rotate(0);
  }

  66% {
    transform: translate(0, -1px)rotate(0);
  }

  68% {
    transform: translate(0, 3px)rotate(0);
  }

  70% {
    transform: translate(0, -9px)rotate(0);
  }

  72% {
    transform: translate(0, 2px)rotate(0);
  }

  74% {
    transform: translate(0, 2px)rotate(0);
  }

  76% {
    transform: translate(0, 9px)rotate(0);
  }

  78% {
    transform: translate(0, -1px)rotate(0);
  }

  80% {
    transform: translate(0, 8px)rotate(0);
  }

  82% {
    transform: translate(0, -3px)rotate(0);
  }

  84% {
    transform: translate(0, -1px)rotate(0);
  }

  86% {
    transform: translate(0, 3px)rotate(0);
  }

  88% {
    transform: translate(0, 7px)rotate(0);
  }

  90% {
    transform: translate(0, 7px)rotate(0);
  }

  92% {
    transform: translate(0, 8px)rotate(0);
  }

  94% {
    transform: translate(0, -6px)rotate(0);
  }

  96% {
    transform: translate(0, 3px)rotate(0);
  }

  98% {
    transform: translate(0, 4px)rotate(0);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-vertical:hover, .shake-trigger:hover .shake-vertical, .shake-vertical.shake-freeze, .shake-vertical.shake-constant {
  animation-name: shake-vertical;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-rotate {
  2% {
    transform: translate(0)rotate(6.5deg);
  }

  4% {
    transform: translate(0)rotate(5.5deg);
  }

  6% {
    transform: translate(0)rotate(-3.5deg);
  }

  8% {
    transform: translate(0)rotate(4.5deg);
  }

  10% {
    transform: translate(0)rotate(5.5deg);
  }

  12% {
    transform: translate(0)rotate(-.5deg);
  }

  14% {
    transform: translate(0)rotate(-4.5deg);
  }

  16% {
    transform: translate(0)rotate(-3.5deg);
  }

  18% {
    transform: translate(0)rotate(3.5deg);
  }

  20% {
    transform: translate(0)rotate(4.5deg);
  }

  22% {
    transform: translate(0)rotate(-.5deg);
  }

  24% {
    transform: translate(0)rotate(4.5deg);
  }

  26% {
    transform: translate(0)rotate(5.5deg);
  }

  28% {
    transform: translate(0)rotate(4.5deg);
  }

  30% {
    transform: translate(0)rotate(-6.5deg);
  }

  32% {
    transform: translate(0)rotate(-3.5deg);
  }

  34% {
    transform: translate(0)rotate(-4.5deg);
  }

  36% {
    transform: translate(0)rotate(-4.5deg);
  }

  38% {
    transform: translate(0)rotate(.5deg);
  }

  40% {
    transform: translate(0)rotate(7.5deg);
  }

  42% {
    transform: translate(0)rotate(-.5deg);
  }

  44% {
    transform: translate(0)rotate(-6.5deg);
  }

  46% {
    transform: translate(0)rotate(-2.5deg);
  }

  48% {
    transform: translate(0)rotate(4.5deg);
  }

  50% {
    transform: translate(0)rotate(1.5deg);
  }

  52% {
    transform: translate(0)rotate(2.5deg);
  }

  54% {
    transform: translate(0)rotate(5.5deg);
  }

  56% {
    transform: translate(0)rotate(-.5deg);
  }

  58% {
    transform: translate(0)rotate(-6.5deg);
  }

  60% {
    transform: translate(0)rotate(-4.5deg);
  }

  62% {
    transform: translate(0)rotate(3.5deg);
  }

  64% {
    transform: translate(0)rotate(3.5deg);
  }

  66% {
    transform: translate(0)rotate(-2.5deg);
  }

  68% {
    transform: translate(0)rotate(-3.5deg);
  }

  70% {
    transform: translate(0)rotate(7.5deg);
  }

  72% {
    transform: translate(0)rotate(-4.5deg);
  }

  74% {
    transform: translate(0)rotate(6.5deg);
  }

  76% {
    transform: translate(0)rotate(3.5deg);
  }

  78% {
    transform: translate(0)rotate(5.5deg);
  }

  80% {
    transform: translate(0)rotate(.5deg);
  }

  82% {
    transform: translate(0)rotate(2.5deg);
  }

  84% {
    transform: translate(0)rotate(-6.5deg);
  }

  86% {
    transform: translate(0)rotate(6.5deg);
  }

  88% {
    transform: translate(0)rotate(-6.5deg);
  }

  90% {
    transform: translate(0)rotate(-3.5deg);
  }

  92% {
    transform: translate(0)rotate(-5.5deg);
  }

  94% {
    transform: translate(0)rotate(-1.5deg);
  }

  96% {
    transform: translate(0)rotate(.5deg);
  }

  98% {
    transform: translate(0)rotate(.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-rotate:hover, .shake-trigger:hover .shake-rotate, .shake-rotate.shake-freeze, .shake-rotate.shake-constant {
  animation-name: shake-rotate;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-opacity {
  10% {
    opacity: .56;
    transform: translate(-3px, -2px)rotate(-.5deg);
  }

  20% {
    opacity: .55;
    transform: translate(2px)rotate(2.5deg);
  }

  30% {
    opacity: .95;
    transform: translate(2px, -1px)rotate(1.5deg);
  }

  40% {
    opacity: .26;
    transform: translate(2px, 4px)rotate(1.5deg);
  }

  50% {
    opacity: .08;
    transform: translate(-3px, -4px)rotate(2.5deg);
  }

  60% {
    opacity: .2;
    transform: translate(2px, -4px)rotate(-.5deg);
  }

  70% {
    opacity: .97;
    transform: translate(0, -2px)rotate(-1.5deg);
  }

  80% {
    opacity: .41;
    transform: translate(-4px, -1px)rotate(.5deg);
  }

  90% {
    opacity: .67;
    transform: translate(-1px)rotate(-.5deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-opacity:hover, .shake-trigger:hover .shake-opacity, .shake-opacity.shake-freeze, .shake-opacity.shake-constant {
  animation-name: shake-opacity;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-crazy {
  10% {
    opacity: .76;
    transform: translate(-5px, -16px)rotate(4deg);
  }

  20% {
    opacity: .25;
    transform: translate(19px, -6px)rotate(6deg);
  }

  30% {
    opacity: .32;
    transform: translate(11px, -3px)rotate(-3deg);
  }

  40% {
    opacity: .46;
    transform: translate(-4px, -5px)rotate(7deg);
  }

  50% {
    opacity: .94;
    transform: translate(-16px, -18px)rotate(-8deg);
  }

  60% {
    opacity: .01;
    transform: translate(6px, -4px)rotate(-4deg);
  }

  70% {
    opacity: .56;
    transform: translate(-2px, 3px)rotate(-1deg);
  }

  80% {
    opacity: .7;
    transform: translate(15px, 5px)rotate(9deg);
  }

  90% {
    opacity: .67;
    transform: translate(-5px, 13px)rotate(8deg);
  }

  0%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-crazy:hover, .shake-trigger:hover .shake-crazy, .shake-crazy.shake-freeze, .shake-crazy.shake-constant {
  animation-name: shake-crazy;
  animation-duration: .1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-chunk {
  2% {
    transform: translate(9px, -10px)rotate(-10deg);
  }

  4% {
    transform: translate(10px, 13px)rotate(8deg);
  }

  6% {
    transform: translate(-14px, -9px)rotate(11deg);
  }

  8% {
    transform: translate(-9px, 4px)rotate(6deg);
  }

  10% {
    transform: translate(-6px, 2px)rotate(7deg);
  }

  12% {
    transform: translate(-7px, -4px)rotate(5deg);
  }

  14% {
    transform: translate(12px, -7px)rotate(-6deg);
  }

  16% {
    transform: translate(14px, -12px)rotate(6deg);
  }

  18% {
    transform: translate(12px)rotate(5deg);
  }

  20% {
    transform: translate(-6px, 13px)rotate(10deg);
  }

  22% {
    transform: translate(-7px)rotate(0);
  }

  24% {
    transform: translate(6px, 1px)rotate(4deg);
  }

  26% {
    transform: translate(15px, 7px)rotate(-8deg);
  }

  28% {
    transform: translate(4px, 10px)rotate(12deg);
  }

  30% {
    transform: translate(-4px, -7px)rotate(-3deg);
  }

  32% {
    transform: translate(11px, 13px)rotate(3deg);
  }

  34% {
    transform: translate(5px, 8px)rotate(1deg);
  }

  36% {
    transform: translate(13px, 4px)rotate(13deg);
  }

  38% {
    transform: translate(-9px, 12px)rotate(11deg);
  }

  0%, 40%, 100% {
    transform: translate(0)rotate(0);
  }
}

.shake-chunk:hover, .shake-trigger:hover .shake-chunk, .shake-chunk.shake-freeze, .shake-chunk.shake-constant {
  animation-name: shake-chunk;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/*# sourceMappingURL=index.4b3237b4.css.map */
